.main-nav {
    background: #000;
    top: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    z-index: 999;
    height: auto;
}
.mean-nav .dropdown-toggle::after {
	display: none;
}
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover {
    color: #fff;
    font-weight: bold;
    line-height: 1;
}
.main-nav nav .navbar-nav .nav-item {
    position: relative;
    padding: 15px 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    background: #0d1028;
    position: absolute;
    top: 80px;
    left: 0;
    width: 250px;
    z-index: 99;
    display: block;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    padding: 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 9px 15px;
    margin: 0;
    display: block;
    color: #ffffff;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu li:hover .dropdown-menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #ffffff;
    text-transform: capitalize;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: #ffffff;
}
.main-nav nav .navbar-nav .nav-item:hover ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.main-nav nav .navbar-nav .nav-item:last-child .dropdown-menu {
    left: auto;
    right: 0;
}