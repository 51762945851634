@media only screen and (max-width: 767px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    h3 {
        font-size: 18px;
    }
    .default-btn {
        font-size: 15px;
        padding: 10px 20px;
        &.active {
            margin-left: 0;
        }
    }
    .top-title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .section-title {
        margin-bottom: 30px;
        h2 {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    .header-area {
        .top-header-area {
            .header-content-left {
                margin-bottom: 15px;
                li{
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .header-content-right {
                float: unset;
                li {
                    .option-item {
                        display: none;
                    }
                }
            }
        }
    }
    .main-banner-area {
        height: 100%;
        text-align: center;
        padding-top: 50px;
        .banner-img-wrap {
            .banner-img {
                max-width: 350px;
                position: unset;
                margin: 30px auto 0;
            }
            .banner-shape {
                right: 0;
                top: 0;
                left: 0;
                max-width: 100%;
                max-width: 350px;
                margin: auto;
            }
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
        .banner-text {
            position: relative;
            max-width: 100%;
            margin-left: auto;
            margin-top: 0;
            span {
                font-size: 14px;
                margin-bottom: 10px;
            }
            h1 {
                font-size: 30px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: unset;
            }
        }
        .active {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    .first-facility-area {
        position: relative;
        max-width: 100%;
        width: auto;
        padding: 20px;
        padding-top: 50px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -150px;
            width: 200px;
            height: 100%;
            background-color: #d6fbfd;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -150px;
            width: 200px;
            height: 100%;
            background-color: #d6fbfd;
            z-index: -1;
        }
        .first-facility-item {
            padding-left: 0;
            text-align: center;
            margin-bottom: 30px;
            i {
                position: unset;
                margin-bottom: 15px;
            }
        }
        .shape {
            display: none;
        }
    }
    .second-facility-item {
        padding: 20px;
        img {
            max-width: 50px;
        }
    }
    .about-area {
        padding-top: 0;
        .about-img {
            .shape-1 {
                display: none;
            }
            .shape-2 {
                display: none;
            }
            .shape-3 {
                display: none;
            }
        }
        .about-content {
            margin-left: 0;
            margin-top: 30px;
            h2 {
                font-size: 25px;
            }
            .default-btn {
                margin-top: 30px;
            }
        }
    }
    .single-services {
        padding: 20px;
        &::before {
            display: none;
        }
    }
    .single-doctor {
        padding: 20px;
    }
    .our-work-area {
        .shape {
            display: none;
         }
    }
    .single-work {
        .work-title {
            max-width: 265px;
        }
    }
    .single-counter {
        h2 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .appointment-here-form{
        padding: 50px 20px;
        h2 {
            font-size: 25px;
            margin-bottom: 30px;
        }
        .shape {
            display: none;
        }
    }
    .emergency-area {
        padding-bottom: 50px;
        .shape {
            display: none;
        }
        .container-fluid {
            max-width: 540px;
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
    }
    .emergency-content {
        max-width: unset;
        padding-bottom: 20px;
        h2 {
            font-size: 25px;
            margin-bottom: 25px;
        }
        ul {
            li {
                margin-right: 15px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .emergency-img {
       height: 300px;
    }
    .single-client {
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
    }
    .single-blog {
        .blog-content {
            padding: 20px;
        }
    }
    .subscribe-area {
        text-align: center;
        h2 {
            font-size: 25px;
        }
        p {
            font-weight: 500;
            margin-bottom: 30px;
        }
        .newsletter-form {
            .default-btn {
                position: unset;
                margin: 30px auto 0;
                height: 50px;
                &::before {
                    display: none;
                }
                &::after {
                    display: none;
                }
            }
            .form-control {
                height: 50px;
            }
        }
    }
    .single-widget {
        h3 {
            margin-bottom: 15px;
        }
    }
    .go-top.active {
        top: 85%;
    }
    .client-area.c-bg {
        padding-bottom: 50px;
    }
    .main-banner-area-two {
        text-align: center;
        padding-top: 50px;
        .container-fluid {
            max-width: 540px;
            .col-lg-6.pr-0 {
                padding-right: 15px !important; 
            }
        }
        height: 100%;
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 20px;
            }
        }
        .banner-text {
            position: relative;
            max-width: 100%;
            margin-left: auto;
            margin-top: 0;
            span {
                font-size: 14px;
            }
            h1 {
                font-size: 40px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px; 
                font-weight: unset;
            }
        }
        .active {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    section.second-facility-area.two {
        padding-top: 50px;
    }
    .mt-100 {
        margin-top: 50px;
    }
    .about-area.two {
        padding-top: 50px;
    }
    .symptoms-area {
        .symptoms-content {
            margin-bottom: 30px;
            h2 {
                font-size: 25px;
            }
        }
        .single-symptoms {
            margin-bottom: 20px;
            h3 {
                padding-left: 25px;
                font-size: 15px;
            }
        }
    }
    .single-prevention {
        padding-left: 0;
        text-align: center;
        i {
            position: relative;
            margin-bottom: 25px;
            left: 0;
        }
    }
    .make-appointment-area {
        .make-appointment-content {
            h2 {
                margin-bottom: 18px;
                font-size: 24px;
            }
        }
        .contact-form {
            padding: 20px;
        }
    }
    .contact-img {
        margin-top: 30px;
    }
    .prevention-area {
        padding-bottom: 50px;
        .container-fluid {
            max-width: 540px;
        }
    }
    .main-contact-area {
        .contact-form {
            .default-btn {
                float: unset;
                margin-top: 15px;
                display: table;
                margin: 20px auto 0;
            }
            .contact-num {
                text-align: center;
            }
        }
    }
    .faq-img {
        margin-bottom: 30px;
    }
    .about-area.about-page {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .about-img.two {
        margin-top: 30px;
    }
    .page-title-area {
        padding-top: 100px;
        padding-bottom: 100px;
        .page-title-content {
            h2 {
                font-size: 30px;
            }
        }
    }
    .client-area  {
        .single-client.mr-0.ml-0 {
            margin-bottom: 30px;
        }
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    .faq-contact-area {
        .contact-wrap {
            padding: 20px;
        }
    }
    .user-area-all-style {
        .contact-form-action {
            padding: 20px;
            form {
                .default-btn {
                    width: 100%;
                }
            }
        }
    }
    .recover-password-area {
        .contact-form-action {
            .form-heading {
                h3 {
                    font-size: 25px;
                    margin-bottom: 10px;
                }
            }
            .now-register {
                float: unset;
            }
        }
    }
    .services-page-one {
        margin-top: 50px !important;
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    .first-facility-area.mt-0.services-page-one {
        margin-top: 0 !important;
        margin-bottom: 50px;
    }
    .services-details-area {
        img {
            width: 100%;
        }
        .services-img {
            margin-bottom: 30px;
        }
    }
    .services-details-area .scrives-item-3 .share {
        float: left;
        margin-top: 20px;
    }
    .services-details-text {
        padding-left: 15px;
        padding-right: 15px;
    }
    .services-details-text h2 {
        font-size: 25px;
    }
    .doctors-sidebar .availability {
        padding: 20px;
    }
    .doctors-detailss .doctors-history {
        margin-top: 30px;
        padding: 20px;
        h2 {
            font-size: 25px;
        }
        span {
            font-size: 15px;
        }
    }
    .blog-details-area {
        .blog-details-desc {
            .article-content {
                .entry-meta {
                    ul {
                        li {
                            display: block;
                            font-size: 15px;
                            margin-right: 0;
                            margin-bottom: 5px;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
                h3 {
                    line-height: 1.5;
                    margin-bottom: 10px;
                    margin-top: 20px;
                    font-size: 22px;
                }
                p {
                    font-size: 15px;
                }
                .features-list {
                    li {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget-area .widget {
            padding: 0;
        }
        blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .comments-area {
            padding: 20px;
            .comment-author {
                .avatar {
                    left: 0;
                    position: relative;
                    display: block;
                    margin-bottom: 10px;
                }
                font-size: 15px;
                margin-bottom: 0.3em;
            }
            .comment-body {
                padding-left: 0;
            }
            .comments-title {
                font-size: 19px;
                &::before {
                    left: -20px;
                }
            }
            .comment-metadata {
                font-size: 12px;
            }
            .comment-respond {
                .comment-reply-title {
                    font-size: 19px;
                    &::before {
                        left: -20px;
                    }
                }
                .comment-form-author {
                    width: 100%;
                    padding-right: 0;
                }
                .comment-form-email {
                    width: 100%;
                    padding-left: 0;
                }
                .form-submit {
                    input {
                        padding: 12px 25px 10px;
                    }
                }
            }
        }
    }
    .blog-details-area .comments-area .children {
        margin-left: 0;
    }
    .blog-left-sidebar {
        margin-bottom: 30px;
    }
    .blog-right-sidebar {
        margin-top: 30px;
    }
    .right-sidebar {
        margin-top: 35px;
    }
    .single-contact-info {
        padding: 30px;
    }
    .single-contact-map iframe {
        height: 200px;
    }
    .main-contact-area.contact .contact-form {
        padding: 20px;
    }
    .hero-slider-area {
        text-align: center;
        .slider-item {
            height: 100%;
            padding-top: 50px;
            padding-bottom: 80px;
            .slider-text {
                h1 {
                    font-size: 25px;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 30px;
                    font-size: 14px;
                }
            }
        }
        .slider-item {
            .video-btn {
                margin: 20px auto 0;
            }
        }
    }
    .second-facility-area {
        &.three {
            padding-top: 50px;
            margin: 0;
            .container {
                max-width: 1110px;
            }
            .second-facility-item {
                margin-left: 15px;
                margin-right: 15px;
                padding: 20px;
            }
        }
    }
    .about-area-three {
        .about-content {
            h2 {
                font-size: 25px;
            }
            .default-btn {
                margin-top: 5px;
            }
        }
        .about-img {
            margin-top: 30px;
            &::before {
                display: none;
            }
        }
        .shape-3 {
            display: none;
        }
    }
    .how-its-work-content {
        .content-wrap {
            h3 {
                font-size: 16px;
            }
        }
    }
    .how-work-img {
        margin-top: 30px;
    }
    .assessment-img {
        margin-bottom: 30px;
    }
    .assessment-center-content {
        h2 {
            font-size: 25px;
        }
        ul li {
            font-size: 14px;
            h3 {
                font-size: 15px;
            }
        }
    }
    .emergency-contents {
        h2 {
            font-size: 25px;
        }
    }
    .assessments-img {
        margin-top: 30px;
    }
    .operate-area {
        .operate-img {
            position: unset;
            margin-bottom: 30px;
        }
        .operate-text {
            margin-bottom: 30px;
            h2 {
                font-size: 25px;
            }
        }
    }
    .main-banner-area-four {
        text-align: center;
        height: 100%;
        padding-top: 50px;
        .banner-text {
            span {
                margin-bottom: 10px;
            }
            top: 0;
            h1 {
                font-size: 30px;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 20px;
                font-size: 14px;
            }
        }
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 20px;
            }
        }
    }
    .about-area {
        &.four {
            padding-top: 50px;
            .col-lg-6.pl-0 {
                padding-left: 15px !important;
            }
            .col-lg-6.pr-0 {
                padding-right: 15px !important;
            }
        }
    }
    .about-area.four.mission {
        padding-top: 0;
        .about-content {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
    .ours-doctors-area {
        .doctors-content {
            margin-right: 0;
            padding-bottom: 30px;
            h2 {
                font-size: 25px;
            }
        }
        .col-lg-6.pr-0 {
            margin-right: 15px !important;
        }
        .doctors-img {
            height: 300px;
        }
    }
    .main-banner-area-five {
        .container-fluid {
            max-width: 540px;
        }
        text-align: center;
        height: 100%;
        padding-top: 50px;
        .col-lg-5.pr-0 {
            padding-right: 15px !important;
        }
        .banner-text {
            max-width: unset;
            top: 0;
            span {
                margin-bottom: 10px;
            }
            h1 {
                font-size: 30px;
                margin-bottom: 20px;
            }
            p {
                font-size: 15px;
            }
        }
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 30px;
            }
        }
    }
    .search-area {
        margin-top: 50px;
        .top-search-from {
            padding: 20px;
            margin-bottom: 30px;
            .searchs-wraps {
                .form-control {
                    font-size: 15px;
                    padding-top: 0;
                    &::placeholder {
                        font-size: 15px;
                    }
                }
                .search-btn {
                    top: 9px;
                    font-size: 30px;
                }
            }
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    .main-banner-six {
        text-align: center;
        .container-fluid {
            max-width: 540px;
        }
        .main-banner-content {
            .content {
                margin-bottom: 30px;
                max-width: unset;
                margin-top: 50px;
                h1 {
                    font-size: 30px;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
        .banner-image-slider {
            .banner-image {
                height: 500px;
            } 
        }
        .shape {
            display: none;
        }
    }
    .single-best-services {
        .best-services-content{
            padding: 20px;
        }
    }
    .abouts-six {
        padding-top: 0 !important;
        
        .about-content {
            margin-top: 0;
            margin-bottom: 25px;
        }
    }
    .coming-soon-area  {
        .coming-soon-content {
            height: 100%;
            h1 {
                font-size: 40px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 13px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 20px;
                div {
                    font-size: 40px;
                    margin-left: 8px;
                    margin-right: 8px;
                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 35px;
                .input-newsletter {
                    height: 55px;
                    padding-left: 13px;
                    font-size: 15px;
                }
                button {
                    position: relative;
                    height: auto;
                    padding: 14px 30px;
                    font-size: 14px;
                    margin-top: 15px;
                }
            }
        } 
    }
    .error-area {
        padding: 70px 0;
        .error-content-wrap {
            p {
                margin: 15px 0 20px;
            }
            h1 {
                font-size: 100px;
            }
        }
    }
    .symptoms-img {
        position: relative;
        bottom: -20px;
    }
    .main-contact-area #msgSubmit {
        position: unset;
        text-align: center;
        margin-top: 10px;
    }
    .about-bg-1 {
        height: 300px;
    }
    .about-img-2 {
        height: 300px;
    }
    .hero-slider-area-seven {
        .slider-item {
            &::after {
                clip-path: unset;
            }
        }
    }
    .second-facility-area {
        &.seven {
            .p-0 {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
            .shape {
                display: none;
            }
        }
    }
    .about-area-seven {
        .about-content {
            h2 {
                font-size: 25px;
            }
            ul {
                margin-left: 0;
                display: block;
            }
            .ml-0 {
                margin-bottom: 20px;
            }
        }
        .about-img {
            margin-top: 30px;
        }
    }
    .counter-area {
        .shape  {
            display: none;
        }
    }
    .services-area-seven {
        .single-services {
            .services-content {
                padding: 20px;
            }
        }
    }
    .doctors-area-two.seven {
        .shape img {
            display: none;
        }
    }
    .appointment-area-seven {
        .shape {
            display: none;
        }
        .appointment-here-form {
            padding: 20px;
        }
    }
    .blog-area {
        .shape {
            display: none;
        }
    }
    .hero-slider-area-eight {
        .slider-item {
            height: 100% !important;
            .slider-text {
                padding: 20px;
                margin-top: 40px;
            }
        }
    }
    .second-facility-area {
        &.eight {
            padding-top: 50px;
            margin-top: 0;

            .second-facility-item {
                text-align: center;
            }
            .p-0 {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
    }
    .about-img-1 {
        height: 300px;
        margin-top: 30px;
    }
    .about-area-seven {
        .mb-30 {
            margin-bottom: 0 !important;
        }
    }
    .single-pricing h1 {
        font-size: 35px;
    }
    .appointment-here-form {
        .default-btn {
            margin: 0px auto 0;
        }
    }
    .nav-area {
        .navbar-area {
            .main-nav {
                background-color: #25245e;
                padding: 10px 0;
                .container {
                    position: relative;
                }
                nav {
                    padding: 0;

                    .navbar-collapse {
                        border-top: 1px solid #eee;
                        margin-top: 15px;
                        padding-top: 8px;
                        overflow-y: auto;
                        max-height: 60vh;

                        &::-webkit-scrollbar {
                            width: 10px;
                        } 
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1; 
                        } 
                        &::-webkit-scrollbar-thumb {
                            background: #888; 
                        } 
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555; 
                        }
                    }
                    .navbar-nav {
                        padding-right: 15px;
                        .nav-item { 
                            padding: 5px 0;

                            a {
                                i {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                position: relative;
                                opacity: 1;
                                visibility: visible;
                                transform: scaleY(1);
                                width: 100%;
                                margin-top: 10px;
                                top: 0 !important;

                                li {
                                    padding: 0;

                                    .dropdown-menu {
                                        left: 15px;
                                        position: relative;
                                        opacity: 1 !important;
                                        visibility: visible !important;
                                        transform: scaleY(1);
                                        width: 90%;
                                        margin-top: 0;
                                        top: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.four {
            .navbar-area {
                .main-nav {
                    background-color: #ffffff;
                    box-shadow: 0px 0px 10px #eee;
                }

                .navbar-toggler {
                    .icon-bar {
                        background: #25245e;
                    }
                }
            }
        }

        &.five {
            .navbar-area {
                .navbar-toggler {
                    .icon-bar {
                        background: #25245e;
                    }
                }
            }
        }

        .others-option {
            position: absolute;
            right: 60px;
            top: 6px;
            .subscribe {
                .default-btn {
                    padding: 10px 15px;
                    font-size: 12px;
                }
            }
        }
    }
    .header-area {
        .top-header-area {
            text-align: center;
        }
    }
    .modal-video-body {
        padding: 0 20px;
    }
    .hero-slider-area-seven {
        .slider-item {
            .slider-text {
                margin: 0px auto 0;
            }
        }
    }
    .blog-left-sidebar {
        padding-right: 0;
    }
    .blog-right-sidebar {
        padding-left: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px)  {
    .header-area {
        .top-header-area {
            .header-content-right {
                float: right;
            }
        }
    }
    .main-banner-area {
        .container-fluid {
            max-width: 540px;
            margin: auto;
        }
    }
    .second-facility-item {
        padding: 15px;
    }
    .single-work {
        .work-title {
            max-width: 325px;
        }
    }
    .emergency-content ul li {
        margin-right: 30px;
        margin-bottom: 0;
    }
    .subscribe-area {
        .newsletter-form {
            .default-btn {
                position: absolute;
                margin: unset;
                height: 50px;
            }
        }
    }
    .default-btn.active {
        margin-left: 30px;
    }
    .about-area {
        &.four {
            .container-fluid {
                max-width: 540px;
            }
        }
    }
    .ours-doctors-area {
        .container-fluid {
            max-width: 540px;
        }
    }
    .counter-area {
        &.two {
            .single-counter {
                padding: 50px 25px;
            }
        }
    }
    .main-contact-area {
        .contact-form {
            .default-btn {
                float: right;
                margin-top: 20px;
            }
            .contact-num {
                text-align: left;
            }
        }
    }
    .blog-details-area  {
        .blog-details-desc {
            .article-content {
                .entry-meta {
                    ul {
                        li {
                            display: inline-block;
                            font-size: 15px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .coming-soon-area  { 
        .coming-soon-content {
            h1 {
                font-size: 55px; 
            }
            #timer {
                div {
                    font-size: 45px;
                    &::before {
                        font-size: 65px;
                        right: -49px;
                    }
                }
            }
        }
    }
    .main-contact-area #msgSubmit {
        position: absolute;
    }
    .about-area-seven {
        .about-content {
            ul {
                margin-left: 30px;
                display: inline-block;
            }
            .ml-0 {
                margin-bottom: 0;
            }
        }
    }
    .single-doctors-two {
        .doctor-img {
            .doctors-link {
                li {
                    a{
                        margin: 0 2px;
                    }
                }
            }
        }
    }
    .second-facility-area.eight {
        .container {
            max-width: 540px;
        }
    } 
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-left-sidebar {
        padding-right: 0;
    }
    .blog-right-sidebar {
        padding-left: 0;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    h3 {
        font-size: 18px;
    }
    .default-btn {
        font-size: 16px;
        padding: 12px 25px;
    }
    .top-title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .section-title {
        margin-bottom: 30px;
        h2 {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    .header-area {
        .top-header-area {
            .header-content-left {
                li {
                    padding-right: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .header-content-right {
                li {
                    .option-item {
                        display: none;
                    }
                }
            }
        }
    }
    .main-banner-area {
        height: 100%;
        text-align: center;
        padding-top: 115px;
        .container-fluid {
            max-width: 720px;
            margin: auto;
            .col-lg-6.pr {
                padding-right: 15px !important;
            }
        }
        .banner-img-wrap {
            .banner-img {
                max-width: 400px;
                position: unset;
                margin: 30px auto 0;
            }
            .banner-shape {
                right: 0;
                top: 0;
                left: 0;
                max-width: 100%;
                max-width: 400px;
                margin: auto;
            }
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
        .banner-text {
            position: relative;
            max-width: 100%;
            margin-left: auto;
            margin-top: 0;
            span {
                font-size: 14px;
            }
            h1 {
                font-size: 38px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: unset;
            }
        }
        .active {
            margin-top: 10px;
        }
    }
    .first-facility-area {
        position: relative;
        max-width: 100%;
        padding: 20px;
        padding-top: 50px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -150px;
            width: 200px;
            height: 100%;
            background-color: #d6fbfd;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -150px;
            width: 200px;
            height: 100%;
            background-color: #d6fbfd;
            z-index: -1;
        }
        .first-facility-item {
            padding-left: 0;
            text-align: center;
            margin-bottom: 30px;
            i {
                position: unset;
                margin-bottom: 15px;
            }
        }
        .shape {
            display: none;
        }
    }
    .second-facility-item {
        padding: 20px;
        img {
            max-width: 50px;
        }
    }
    .about-area {
        padding-top: 0;
        .about-img {
            .shape-1 {
                display: none;
            }
            .shape-2 {
                display: none;
            }
            .shape-3 {
                display: none;
            }
        }
        .about-content {
            margin-left: 0;
            margin-top: 30px;
            h2 {
                font-size: 25px;
            }
            .default-btn {
                margin-top: 30px;
            }
        }
    }
    .about-img img {
        width: 100%;
    }
    .single-services {
        padding: 20px;
        &::before {
            display: none;
        }
    }
    .single-doctor {
        padding: 20px;
    }
    .our-work-area {
        .shape {
            display: none;
         }
    }
    .single-work {
        .work-title {
            max-width: 265px;
        }
    }
    .single-counter {
        h2 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .appointment-here-form {
        h2 {
            font-size: 25px;
            margin-bottom: 30px;
        }
        .shape {
            display: none;
        }
    }
    .emergency-area {
        padding-bottom: 50px;
        .shape {
            display: none;
        }
        .container-fluid {
            max-width: 720px;
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
    }
    .emergency-content {
        max-width: unset;
        padding-bottom: 20px;
        h2 {
            font-size: 25px;
            margin-bottom: 25px;
        }
    }
    .emergency-img {
       height: 500px;
    }
    .single-client {
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
    }
    .single-blog {
        .blog-content {
            padding: 20px;
        }
    }
    .subscribe-area {
        text-align: center;
        h2 {
            font-size: 25px;
        }
        p {
            font-weight: 500;
            margin-bottom: 30px;
        }
    }
    .single-widget {
        h3 {
            margin-bottom: 15px;
        }
    }
    .client-area.c-bg {
        padding-bottom: 50px;
    }
    .main-banner-area-two {
        text-align: center;
        padding-top: 115px;
        .container-fluid {
            max-width: 720px;
            .col-lg-6.pr-0 {
                padding-right: 15px !important; 
            }
        }
        height: 100%;
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 20px;
            }
        }
        .banner-text {
            position: relative;
            max-width: 100%;
            margin-left: auto;
            margin-top: 0;
            span {
                font-size: 14px;
            }
            h1 {
                font-size: 35px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: unset;
            }
        }
        .active {
            margin-top: 10px;
        }
    }
    section.second-facility-area.two {
        padding-top: 50px;
    }
    .mt-100 {
        margin-top: 50px;
    }
    .about-area.two {
        padding-top: 50px;
    }
    .symptoms-area {
        .symptoms-content {
            margin-bottom: 30px;
            h2 {
                font-size: 25px;
            }
        }
        .single-symptoms {
            margin-bottom: 20px;
            h3 {
                padding-left: 25px;
                font-size: 15px;
            }
        }
    }
    .single-prevention {
        padding-left: 0;
        text-align: center;
        i {
            position: relative;
            margin-bottom: 25px;
            left: 0;
        }
    }
    .make-appointment-area {
        .make-appointment-content {
            h2 {
                margin-bottom: 18px;
                font-size: 24px;
            }
        }
        .contact-form {
            padding: 20px;
        }
    }
    .contact-img {
        margin-top: 30px;
    }
    .prevention-area {
        padding-bottom: 50px;
        .container-fluid {
            max-width: 720px;
        }
    }
    .faq-img {
        margin-bottom: 30px;
    }
    .hero-slider-area {
        text-align: center;
        .slider-item {
            height: 100%;
            padding-top: 115px;
            padding-bottom: 150px;
            .slider-text {
                h1 {
                    font-size: 40px;
                    margin-bottom: 20px;
                    br {
                        display: none;
                    }
                }
                p {
                    margin-bottom: 30px;
                    font-size: 14px;
                }
            }
        }
        .slider-item {
            .video-btn {
                margin: 20px auto 0;
            }
        }
    }
    .second-facility-area {
        &.three {
            padding-top: 50px;
            margin: 0;
            .container {
                max-width: 1110px;
            }
            .second-facility-item {
                margin-left: 15px;
                margin-right: 15px;
                padding: 20px;
            }
        }
    }
    .about-area-three {
        .about-content {
            h2 {
                font-size: 25px;
            }
            .default-btn {
                margin-top: 5px;
            }
        }
        .about-img {
            margin-top: 30px;
            &::before {
                display: none;
            }
        }
        .shape-3 {
            display: none;
        }
    }
    .how-its-work-content {
        .content-wrap {
            h3 {
                font-size: 16px;
            }
        }
    }
    .how-work-img {
        margin-top: 30px;
    }
    .assessment-img {
        margin-bottom: 30px;
    }
    .assessment-center-content {
        h2 {
            font-size: 25px;
        }
        ul li {
            font-size: 14px;
            h3 {
                font-size: 15px;
            }
        }
    }
    .emergency-contents {
        h2 {
            font-size: 25px;
        }
    }
    .assessments-img {
        margin-top: 30px;
        img {
            width: 100%;
        }
    }
    .operate-area {
        .operate-img {
            position: unset;
            margin-bottom: 30px;
        }
        .operate-text {
            margin-bottom: 30px;
            h2 {
                font-size: 25px;
            }
        }
    }
    .main-banner-area-four {
        .container-fluid {
            max-width: 720px;
        }
        text-align: center;
        height: 100%;
        padding-top: 115px;
        .banner-text {
            max-width: unset;
            span {
                margin-bottom: 10px;
            }
            top: 0;
            h1 {
                font-size: 35px;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 20px;
                font-size: 14px;
            }
        }
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 20px;
            }
        }
    }
    .about-area {
        &.four {
            padding-top: 50px;
            .about-content { 
                max-width: unset;
            }
            .container-fluid {
                max-width: 720px;
            }
            .col-lg-6.pl-0 {
                padding-left: 15px !important;
            }
            .col-lg-6.pr-0 {
                padding-right: 15px !important;
            }
        }
    }
    .about-area.four.mission {
        padding-top: 0;
        .about-content {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
    .ours-doctors-area {
        padding-bottom: 50px;
        .container-fluid {
            max-width: 720px;
        }
        .doctors-content {
            padding-bottom: 30px;
            margin-right: 0;
            max-width: unset;
            h2 {
                font-size: 25px;
            }
        }
        .col-lg-6.pr-0 {
            margin-right: 15px !important;
        }
        .doctors-img {
            height: 300px;
        }
    }
    .main-banner-area-five {
        .container-fluid {
            max-width: 720px;
        }
        text-align: center;
        height: 100%;
        padding-top: 115px;
        .col-lg-5.pr-0 {
            padding-right: 15px !important;
        }
        .banner-text {
            max-width: unset;
            top: 0;
            span {
                margin-bottom: 10px;
            }
            h1 {
                font-size: 35px;
                margin-bottom: 20px;
            }
            p {
                font-size: 15px;
            }
        }
        .banner-img-wrap {
            .banner-img {
                position: unset;
                margin-top: 30px;
            }
        }
    }
    .top-search-from {
        margin-bottom: 30px;
    }
    .main-banner-six {
        text-align: center;
        .container-fluid {
            max-width: 720px;
        }
        .main-banner-content {
            .content {
                margin-bottom: 30px;
                max-width: unset;
                margin-top: 80px;
                h1 {
                    font-size: 30px;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
        .col-lg-6.pr-0 {
            padding-right: 15px !important;
        }
        .banner-image-slider {
            .banner-image {
                height: 500px;
            } 
        }
        .shape {
            display: none;
        }
    }
    .single-best-services {
        .best-services-content{
            padding: 20px;
        }
    }
    .abouts-six {
        padding-top: 0 !important;
        
        .about-content {
            margin-top: 0;
            margin-bottom: 25px;
        }
    }
    .about-area.about-page {
        padding-top: 50px;
    }
    .about-img.two {
        margin-top: 30px;
    }
    .page-title-area {
        padding-top: 120px;
        padding-bottom: 120px;
        .page-title-content {
            h2 {
                font-size: 30px;
            }
        }
    }
    .client-area  {
        .single-client.mr-0.ml-0 {
            margin-bottom: 30px;
        }
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    .faq-contact-area {
        .contact-wrap {
            padding: 30px;
        }
    }
    .services-page-one {
        margin-top: 50px !important;
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    .first-facility-area.mt-0.services-page-one {
        margin-top: 0 !important;
        margin-bottom: 50px;
    }
    .services-details-area {
        img {
            width: 100%;
        }
        .services-img {
            margin-bottom: 30px;
        }
    }
    .services-details-area .scrives-item-3 .share {
        float: left;
        margin-top: 20px;
    }
    .services-details-text {
        padding-left: 15px;
        padding-right: 15px;
    }
    .services-details-text h2 {
        font-size: 25px;
    }
    .doctors-sidebar {
        img {
            width: 100%;
        }
        .availability {
            padding: 20px;
        }
    }
    .doctors-detailss .doctors-history {
        margin-top: 30px;
        padding: 20px;
        h2 {
            font-size: 25px;
        }
        span {
            font-size: 15px;
        }
    }
    .b-d-s-img img {
        width: 100%;
    }
    aside#secondary {
        margin-top: 35px;
    }
    .blog-left-sidebar {
        margin-bottom: 30px;
    }
    .blog-right-sidebar {
        margin-top: 30px;
    }
    .single-contact-info {
        padding: 50px;
    }
    .single-contact-map iframe {
        height: 300px;
    }
    .symptoms-img {
        position: relative;
        bottom: -20px;
    }
    .about-area.about-page {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .about-bg-1 {
        height: 400px;
    }
    .about-img-2 {
        height: 300px;
    }

    .hero-slider-area-seven {
        .slider-item {
            &::after {
                clip-path: unset;
            }
        }
    }
    .second-facility-area {
        &.seven {
            .p-0 {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
            .shape {
                display: none;
            }
        }
    }
    .about-area-seven {
        .about-content {
            h2 {
                font-size: 25px;
            }
            ul {
                margin-left: 0;
                display: block;
            }
            .ml-0 {
                margin-bottom: 20px;
            }
        }
        .about-img {
            margin-top: 30px;
        }
    }
    .counter-area {
        .shape  {
            display: none;
        }
    }
    .services-area-seven {
        .single-services {
            .services-content {
                padding: 20px;
            }
        }
    }
    .doctors-area-two.seven {
        .shape img {
            display: none;
        }
    }
    .appointment-area-seven {
        .shape {
            display: none;
        }
        .appointment-here-form {
            padding: 20px;
        }
    }
    .blog-area {
        .shape {
            display: none;
        }
    }
    .about-area-seven {
        .about-content {
            ul {
                margin-left: 30px;
                display: inline-block;
            }
            .ml-0 {
                margin-bottom: 0;
            }
        }
    }
    .hero-slider-area-eight {
        .slider-item {
            height: 100% !important;
            .slider-text {
                padding: 20px;
                margin: 40px auto 0;
            }
        }
    }
    .second-facility-area {
        &.eight {
            .container {
                max-width: 720px;
            }
            padding-top: 20px;
            margin-top: 0;

            .second-facility-item {
                text-align: center;
            }
            .p-0 {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
    }
    .about-img-1 {
        height: 300px;
        margin-top: 30px;
    }
    .about-area-seven {
        .mb-30 {
            margin-bottom: 0 !important;
        }
    }
    .single-pricing h1 {
        font-size: 35px;
    }
    .appointment-here-form {
        .default-btn {
            margin: 0px auto 0;
        }
    }
    .second-facility-area {
        &.seven {
            .container {
                max-width: 720px;
            }
        }
    }
    .nav-area {
        .navbar-area {
            .main-nav {
                background-color: #25245e;
                nav {
                    padding: 10px 0;
                    .navbar-nav { 
                        .nav-item { 
                            padding: 6px 0;
                            a {
                                font-size: 14px;
                                margin: 0 7px;
                                i {
                                    font-size: 12px;
                                    top: 2px;
                                }
                            }
                            .dropdown-menu {
                                width: 220px;
                                li {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                .subscribe {
                    .default-btn {
                        padding: 10px 9px;
                        font-size: 12px;
                    }
                }
            }
        }
        &.four {
            .navbar-area {
                .main-nav {
                    background-color: #ffffff;
                    box-shadow: 0px 0px 10px #eee;
                }

                .navbar-toggler {
                    .icon-bar {
                        background: #25245e;
                    }
                }
            }
        }
        &.five {
            .navbar-area {
                .navbar-toggler {
                    .icon-bar {
                        background: #25245e;
                    }
                }
            }
        }
    }
    .hero-slider-area-seven {
        .slider-item {
            .slider-text {
                margin: 0 auto 0;
            }
        }
    }
    .first-facility-area.services-page-one {
        width: 100%;
    } 
    .search-area {
        .form-group {
            margin-bottom: 20px;
        }
    }
}

@media only screen and (min-width : 992px) and (max-width : 1199px) {
    .blog-left-sidebar {
        padding-right: 0;
    }
    .blog-right-sidebar {
        padding-left: 0;
    }
    .pb-130 {
        padding-bottom: 100px;
    }
    .main-banner-area {
        height: 1000px;
        .container-fluid {
            max-width: 960px;
        }
        .banner-text {
            top: -20px;
            h1 {
                font-size: 50px;
            }
            p {
                font-weight: 500;
            }
        }
        .banner-img-wrap {
            .banner-img {
                top: -190px;
            }
        }
    }
    .about-area {
        .about-content {
            h2 {
                font-size: 35px;
            }
        }
    }
    .top-title {
        font-size: 14px;
    }
    .section-title {
        h2 {
            font-size: 35px;
        }
    }
    .appointment-here-form {
        h2 {
            font-size: 35px;
        }
    }
    .emergency-content {
        h2 {
            font-size: 35px;
        }
    }
    .emergency-area {
        .shape {
            display: none;
        }
        .container-fluid {
            max-width: 990px;
            margin-left: auto;
            margin-right: unset;
        }
    }
    .single-blog {
        .blog-content{
            padding: 20px;
        }
    }
    .subscribe-area {
        h2 {
            font-size: 35px;
        }
    }
    .main-banner-area-two {
        .container-fluid {
            max-width: 960px;
        }
        .banner-text {
            top: 10px;
            h1 {
                font-size: 50px;
            }
            p {
                font-weight: 500;
            }
        }
        .banner-img-wrap {
            .banner-img {
                top: 25px;
            }
        }
    }
    .about-area {
        &.two {
            .about-img {
                .shape-1 {
                    left: -75px;
                    top: -70px;
                    img {
                        max-width: 560px;
                    }
                }
            }
            .about-content {
                margin-top: -6px;
                margin-bottom: -6px;
            }
        }
    }
    .symptoms-area {
        padding-bottom: 85px;
    }
    .single-symptoms {
        h3 {
            font-size: 16px;
        }
    }
    .counter-area {
        &.two {
            .single-counter {
                padding: 50px 25px;
                h2 {
                    font-size: 30px;
                }
            }
        }
    }
    .prevention-area {
        .container-fluid {
            max-width: 990px;
        }
    }
    .user-area-all-style {
        .contact-form-action {
            padding: 20px;
            form {
                .default-btn {
                    padding: 15px 35px;
                }
            }
        }
    }
    .user-area-all-style .log-in-img {
        height: 400px;
    }
    .doctors-detailss .doctors-history h2 {
        font-size: 30px;
    }
    .single-contact-info {
        padding: 50px 30px;
    }
    .single-contact-map iframe {
        height: 323px;
    }
    .first-facility-area {
        width: 960px;
        margin: auto;
        right: 0;
    }
    .first-facility-item {
        padding-left: 80px;
        i {
            width: 65px;
            height: 65px;
            line-height: 65px;
            font-size: 35px;
        }
    }
    .about-area-three {
        .about-img {
            &::before {
                display: none;
            }
        }
        .shape-3 {
            display: none;
        }
    }
    .operate-area .operate-img {
        bottom: -148px;
    }
    .main-banner-area-four {
        .banner-text {
            max-width: 540px;
            top: 55px;
            h1 {
                font-size: 55px;
            }
        }
        .banner-img-wrap {
            .banner-img {
                top: 0;
            }
        }
    }
    .about-area.four {
        .container-fluid {
            max-width: 945px;
        }
        .about-content {
            margin-left: 0;
        }
    }
    .ours-doctors-area {
        .doctors-content {
            max-width: 455px;
            margin-right: 0;
        }
    }
    .main-banner-six {
        .shape-2 {
            top: 84%;
            left: 17%;
        }
        .main-banner-content .content {
            max-width: 450px;
        }
    }
    .single-professionals {
        .location {
            .right {
                margin-left: 10px;
            }
        }
    }
    .symptoms-img {
        position: relative;
        bottom: -10px;
    }
    .second-facility-area {
        &.seven {
            .container {
                max-width: 930px;
            }
        }
    }
    .single-blog {
        .blog-content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .second-facility-area {
        &.eight {
            margin-top: 0;
            .container {
                max-width: 930px;
            }
            .second-facility-item {
                padding: 20px;
            }
        }
    }
    .about-area-seven {
        .about-content {
            h2 {
                font-size: 35px;
            }
        }
    }
    .hero-slider-area-eight {
        .slider-item {
            height: 100% !important;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
    .hero-slider-area-seven {
        .slider-item {
            height: 100% !important;
            padding-top: 120px;
            padding-bottom: 100px;
        }
    }
    .main-banner-area-five {
        .banner-text {
            max-width: 535px;
            h1 {
                font-size: 55px;
            }
        }
    }
    .hero-slider-area-seven {
        .slider-item {
            .slider-text {
                margin: 0 auto 0;
            }
        }
    }
    .hero-slider-area-eight {
        .slider-item {
            .slider-text {
                margin-top: 0;
                h1 {
                    font-size: 45px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1700px) {
    .main-banner-area {
        height: 100vh;
        .banner-text {
            margin-top: -70px;
        }
        .banner-img-wrap {
            .banner-img {
                max-width: unset;
                top: -125px;
            }
            .banner-shape {
                max-width: unset;
                right: -155px;
            }
        }
    }
    .first-facility-area { 
        bottom: 0;
    }
    .main-banner-area-two {
        height: 100vh;
        .banner-img-wrap {
            .banner-img {
                top: -200px;
            }
        }
    }
    .main-banner-area-four {
        height: 100vh;
    }
    .main-banner-area-five {
        .banner-img-wrap {
            .banner-img {
                top: -100px;
            }
        }
    }
    .main-banner-six {
        .shape {
            width: unset;
        }
        .shape-2 {
            width: unset;
        }
    }
    .hero-slider-area {
        .slider-item {
            height: 100vh;
        }
    }
    .emergency-area {
        .shape {
            display: block;
            max-width: 240px;
            width: 100%;
        }
    }
    .main-banner-area-five {
        .banner-text {
            max-width: 690px;
        }
    }
    .main-banner-area-four {
        .banner-text {
            max-width: 790px !important;
        }
    }
    .main-banner-area-five {
        .banner-text {
            max-width: 795px !important;
        }
    }
    .prevention-area {
        .prevention-wrap {
            max-width: 965px !important;
        }
    }
}

@media only screen and (max-width: 991px) {
    .mean-container {
        .mean-bar {
            background-color: #25245e;
            box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.05);
            height: 70px;
        }
        a {
            &.meanmenu-reveal {
                padding: 24px 15px 0 0;
                color: #ffffff;
                span {
                    background-color: #ffffff;
                    position: relative;
                    top: 8px;
                    margin-top: -5px;
                }
            }
        }
    }
    .mobile-nav .logo {
        top: 14px;
    }
    .mobile-nav {
        &.mean-container {
            .mean-nav {
                margin-top: 70px;
                ul {
                    li {
                        a {
                            &:hover {
                                color: #05e8c2;
                            }
                            &.active {
                                color: #05e8c2;
                            }
                        }
                    }
                }
            }
        }
    }
    .fixed-top {
        position: unset;
    }
    .mobile-nav nav .navbar-nav {
        height: 325px;
    }
} 

@media only screen and (min-width: 1400px) { 
    .main-banner-area {
        .banner-text {
            max-width: 630px;
        }
    }
    .main-banner-area-two {
        .banner-text {
            max-width: 635px;
        }
    }
    .prevention-area {
        .prevention-wrap {
            max-width: 900px;
            margin-left: auto;
        }
    }
    .second-facility-area {
        &.three {
            .container {
                max-width: 1290px;
                width: 100%;
            }
        }
    }
    .main-banner-area-four {
        .banner-text {
            max-width: 765px;
        } 
    }
    .about-area.four {
        .about-content {
            max-width: 635px;
        }
    }
    .ours-doctors-area {
        .doctors-content {
            max-width: 600px;
        }
    }
    .main-banner-area-five {
        .banner-text {
            max-width: 765px;
            width: 100%;
        }
    }
    .emergency-content {
        max-width: 640px;
    }
    .main-banner-six {
        .main-banner-content {
            .content {
                max-width: 635px;
            }
        }
    }
    .second-facility-area.seven {
        .container {
            max-width: 1295px;
            width: 100%;
        }
    }
    .second-facility-area.eight {
        .container {
            max-width: 1295px;
        } 
    }
    .first-facility-area.services-page-one {
        max-width: 1290px;
        width: 100%;
    }
}