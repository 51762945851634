@media only screen and (max-width: 767px) {
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 20px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .pb-130 {
    padding-bottom: 40px;
  }
  h3 {
    font-size: 18px;
  }
  .default-btn {
    font-size: 15px;
    padding: 10px 20px;
  }
  .default-btn.active {
    margin-left: 0;
  }
  .top-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .section-title {
    margin-bottom: 30px;
  }
  .section-title h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .header-area .top-header-area .header-content-left {
    margin-bottom: 15px;
  }
  .header-area .top-header-area .header-content-left li {
    margin-bottom: 10px;
  }
  .header-area .top-header-area .header-content-left li:last-child {
    margin-bottom: 0;
  }
  .header-area .top-header-area .header-content-right {
    float: unset;
  }
  .header-area .top-header-area .header-content-right li .option-item {
    display: none;
  }
  .main-banner-area {
    height: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .main-banner-area .banner-img-wrap .banner-img {
    max-width: 350px;
    position: unset;
    margin: 30px auto 0;
  }
  .main-banner-area .banner-img-wrap .banner-shape {
    right: 0;
    top: 0;
    left: 0;
    max-width: 100%;
    max-width: 350px;
    margin: auto;
  }
  .main-banner-area .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area .banner-text {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-top: 0;
  }
  .main-banner-area .banner-text span {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .main-banner-area .banner-text h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .main-banner-area .banner-text p {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: unset;
  }
  .main-banner-area .active {
    margin-top: 10px;
    margin-left: 10px;
  }
  .first-facility-area {
    position: relative;
    max-width: 100%;
    width: auto;
    padding: 20px;
    padding-top: 50px;
    z-index: 1;
  }
  .first-facility-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150px;
    width: 200px;
    height: 100%;
    background-color: #d6fbfd;
    z-index: -1;
  }
  .first-facility-area::after {
    content: "";
    position: absolute;
    top: 0;
    right: -150px;
    width: 200px;
    height: 100%;
    background-color: #d6fbfd;
    z-index: -1;
  }
  .first-facility-area .first-facility-item {
    padding-left: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .first-facility-area .first-facility-item i {
    position: unset;
    margin-bottom: 15px;
  }
  .first-facility-area .shape {
    display: none;
  }
  .second-facility-item {
    padding: 20px;
  }
  .second-facility-item img {
    max-width: 50px;
  }
  .about-area {
    padding-top: 0;
  }
  .about-area .about-img .shape-1 {
    display: none;
  }
  .about-area .about-img .shape-2 {
    display: none;
  }
  .about-area .about-img .shape-3 {
    display: none;
  }
  .about-area .about-content {
    margin-left: 0;
    margin-top: 30px;
  }
  .about-area .about-content h2 {
    font-size: 25px;
  }
  .about-area .about-content .default-btn {
    margin-top: 30px;
  }
  .single-services {
    padding: 20px;
  }
  .single-services::before {
    display: none;
  }
  .single-doctor {
    padding: 20px;
  }
  .our-work-area .shape {
    display: none;
  }
  .single-work .work-title {
    max-width: 265px;
  }
  .single-counter h2 {
    font-size: 30px;
  }
  .single-counter p {
    font-size: 15px;
  }
  .appointment-here-form {
    padding: 50px 20px;
  }
  .appointment-here-form h2 {
    font-size: 25px;
    margin-bottom: 30px;
  }
  .appointment-here-form .shape {
    display: none;
  }
  .emergency-area {
    padding-bottom: 50px;
  }
  .emergency-area .shape {
    display: none;
  }
  .emergency-area .container-fluid {
    max-width: 540px;
  }
  .emergency-area .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .emergency-content {
    max-width: unset;
    padding-bottom: 20px;
  }
  .emergency-content h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .emergency-content ul li {
    margin-right: 15px;
    margin-bottom: 10px;
  }
  .emergency-content ul li:last-child {
    margin-bottom: 0;
  }
  .emergency-img {
    height: 300px;
  }
  .single-client {
    padding: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .single-blog .blog-content {
    padding: 20px;
  }
  .subscribe-area {
    text-align: center;
  }
  .subscribe-area h2 {
    font-size: 25px;
  }
  .subscribe-area p {
    font-weight: 500;
    margin-bottom: 30px;
  }
  .subscribe-area .newsletter-form .default-btn {
    position: unset;
    margin: 30px auto 0;
    height: 50px;
  }
  .subscribe-area .newsletter-form .default-btn::before {
    display: none;
  }
  .subscribe-area .newsletter-form .default-btn::after {
    display: none;
  }
  .subscribe-area .newsletter-form .form-control {
    height: 50px;
  }
  .single-widget h3 {
    margin-bottom: 15px;
  }
  .go-top.active {
    top: 85%;
  }
  .client-area.c-bg {
    padding-bottom: 50px;
  }
  .main-banner-area-two {
    text-align: center;
    padding-top: 50px;
    height: 100%;
  }
  .main-banner-area-two .container-fluid {
    max-width: 540px;
  }
  .main-banner-area-two .container-fluid .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area-two .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 20px;
  }
  .main-banner-area-two .banner-text {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-top: 0;
  }
  .main-banner-area-two .banner-text span {
    font-size: 14px;
  }
  .main-banner-area-two .banner-text h1 {
    font-size: 40px;
    margin-bottom: 15px;
  }
  .main-banner-area-two .banner-text p {
    margin-bottom: 20px;
    font-weight: unset;
  }
  .main-banner-area-two .active {
    margin-top: 10px;
    margin-left: 10px;
  }
  section.second-facility-area.two {
    padding-top: 50px;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .about-area.two {
    padding-top: 50px;
  }
  .symptoms-area .symptoms-content {
    margin-bottom: 30px;
  }
  .symptoms-area .symptoms-content h2 {
    font-size: 25px;
  }
  .symptoms-area .single-symptoms {
    margin-bottom: 20px;
  }
  .symptoms-area .single-symptoms h3 {
    padding-left: 25px;
    font-size: 15px;
  }
  .single-prevention {
    padding-left: 0;
    text-align: center;
  }
  .single-prevention i {
    position: relative;
    margin-bottom: 25px;
    left: 0;
  }
  .make-appointment-area .make-appointment-content h2 {
    margin-bottom: 18px;
    font-size: 24px;
  }
  .make-appointment-area .contact-form {
    padding: 20px;
  }
  .contact-img {
    margin-top: 30px;
  }
  .prevention-area {
    padding-bottom: 50px;
  }
  .prevention-area .container-fluid {
    max-width: 540px;
  }
  .main-contact-area .contact-form .default-btn {
    float: unset;
    margin-top: 15px;
    display: table;
    margin: 20px auto 0;
  }
  .main-contact-area .contact-form .contact-num {
    text-align: center;
  }
  .faq-img {
    margin-bottom: 30px;
  }
  .about-area.about-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-img.two {
    margin-top: 30px;
  }
  .page-title-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .page-title-area .page-title-content h2 {
    font-size: 30px;
  }
  .client-area .single-client.mr-0.ml-0 {
    margin-bottom: 30px;
  }
  .page-navigation-area {
    margin: 0px auto 0;
  }
  .faq-contact-area .contact-wrap {
    padding: 20px;
  }
  .user-area-all-style .contact-form-action {
    padding: 20px;
  }
  .user-area-all-style .contact-form-action form .default-btn {
    width: 100%;
  }
  .recover-password-area .contact-form-action .form-heading h3 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .recover-password-area .contact-form-action .now-register {
    float: unset;
  }
  .services-page-one {
    margin-top: 50px !important;
  }
  .services-page-one::before {
    display: none;
  }
  .services-page-one::after {
    display: none;
  }
  .first-facility-area.mt-0.services-page-one {
    margin-top: 0 !important;
    margin-bottom: 50px;
  }
  .services-details-area img {
    width: 100%;
  }
  .services-details-area .services-img {
    margin-bottom: 30px;
  }
  .services-details-area .scrives-item-3 .share {
    float: left;
    margin-top: 20px;
  }
  .services-details-text {
    padding-left: 15px;
    padding-right: 15px;
  }
  .services-details-text h2 {
    font-size: 25px;
  }
  .doctors-sidebar .availability {
    padding: 20px;
  }
  .doctors-detailss .doctors-history {
    margin-top: 30px;
    padding: 20px;
  }
  .doctors-detailss .doctors-history h2 {
    font-size: 25px;
  }
  .doctors-detailss .doctors-history span {
    font-size: 15px;
  }
  .blog-details-area .blog-details-desc .article-content .entry-meta ul li {
    display: block;
    font-size: 15px;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .blog-details-area .blog-details-desc .article-content .entry-meta ul li::before {
    display: none;
  }
  .blog-details-area .blog-details-desc .article-content h3 {
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 22px;
  }
  .blog-details-area .blog-details-desc .article-content p {
    font-size: 15px;
  }
  .blog-details-area .blog-details-desc .article-content .features-list li {
    font-size: 15px;
  }
  .blog-details-area .widget-area .widget {
    padding: 0;
  }
  .blog-details-area blockquote {
    padding: 30px !important;
  }
  .blog-details-area blockquote p {
    font-size: 16px !important;
  }
  .blog-details-area .blockquote {
    padding: 30px !important;
  }
  .blog-details-area .blockquote p {
    font-size: 16px !important;
  }
  .blog-details-area .comments-area {
    padding: 20px;
  }
  .blog-details-area .comments-area .comment-author {
    font-size: 15px;
    margin-bottom: 0.3em;
  }
  .blog-details-area .comments-area .comment-author .avatar {
    left: 0;
    position: relative;
    display: block;
    margin-bottom: 10px;
  }
  .blog-details-area .comments-area .comment-body {
    padding-left: 0;
  }
  .blog-details-area .comments-area .comments-title {
    font-size: 19px;
  }
  .blog-details-area .comments-area .comments-title::before {
    left: -20px;
  }
  .blog-details-area .comments-area .comment-metadata {
    font-size: 12px;
  }
  .blog-details-area .comments-area .comment-respond .comment-reply-title {
    font-size: 19px;
  }
  .blog-details-area .comments-area .comment-respond .comment-reply-title::before {
    left: -20px;
  }
  .blog-details-area .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .blog-details-area .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .blog-details-area .comments-area .comment-respond .form-submit input {
    padding: 12px 25px 10px;
  }
  .blog-details-area .comments-area .children {
    margin-left: 0;
  }
  .blog-left-sidebar {
    margin-bottom: 30px;
  }
  .blog-right-sidebar {
    margin-top: 30px;
  }
  .right-sidebar {
    margin-top: 35px;
  }
  .single-contact-info {
    padding: 30px;
  }
  .single-contact-map iframe {
    height: 200px;
  }
  .main-contact-area.contact .contact-form {
    padding: 20px;
  }
  .hero-slider-area {
    text-align: center;
  }
  .hero-slider-area .slider-item {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .hero-slider-area .slider-item .slider-text h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .hero-slider-area .slider-item .slider-text p {
    margin-bottom: 30px;
    font-size: 14px;
  }
  .hero-slider-area .slider-item .video-btn {
    margin: 20px auto 0;
  }
  .second-facility-area.three {
    padding-top: 50px;
    margin: 0;
  }
  .second-facility-area.three .container {
    max-width: 1110px;
  }
  .second-facility-area.three .second-facility-item {
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
  }
  .about-area-three .about-content h2 {
    font-size: 25px;
  }
  .about-area-three .about-content .default-btn {
    margin-top: 5px;
  }
  .about-area-three .about-img {
    margin-top: 30px;
  }
  .about-area-three .about-img::before {
    display: none;
  }
  .about-area-three .shape-3 {
    display: none;
  }
  .how-its-work-content .content-wrap h3 {
    font-size: 16px;
  }
  .how-work-img {
    margin-top: 30px;
  }
  .assessment-img {
    margin-bottom: 30px;
  }
  .assessment-center-content h2 {
    font-size: 25px;
  }
  .assessment-center-content ul li {
    font-size: 14px;
  }
  .assessment-center-content ul li h3 {
    font-size: 15px;
  }
  .emergency-contents h2 {
    font-size: 25px;
  }
  .assessments-img {
    margin-top: 30px;
  }
  .operate-area .operate-img {
    position: unset;
    margin-bottom: 30px;
  }
  .operate-area .operate-text {
    margin-bottom: 30px;
  }
  .operate-area .operate-text h2 {
    font-size: 25px;
  }
  .main-banner-area-four {
    text-align: center;
    height: 100%;
    padding-top: 50px;
  }
  .main-banner-area-four .banner-text {
    top: 0;
  }
  .main-banner-area-four .banner-text span {
    margin-bottom: 10px;
  }
  .main-banner-area-four .banner-text h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .main-banner-area-four .banner-text p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .main-banner-area-four .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 20px;
  }
  .about-area.four {
    padding-top: 50px;
  }
  .about-area.four .col-lg-6.pl-0 {
    padding-left: 15px !important;
  }
  .about-area.four .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .about-area.four.mission {
    padding-top: 0;
  }
  .about-area.four.mission .about-content {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .ours-doctors-area .doctors-content {
    margin-right: 0;
    padding-bottom: 30px;
  }
  .ours-doctors-area .doctors-content h2 {
    font-size: 25px;
  }
  .ours-doctors-area .col-lg-6.pr-0 {
    margin-right: 15px !important;
  }
  .ours-doctors-area .doctors-img {
    height: 300px;
  }
  .main-banner-area-five {
    text-align: center;
    height: 100%;
    padding-top: 50px;
  }
  .main-banner-area-five .container-fluid {
    max-width: 540px;
  }
  .main-banner-area-five .col-lg-5.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area-five .banner-text {
    max-width: unset;
    top: 0;
  }
  .main-banner-area-five .banner-text span {
    margin-bottom: 10px;
  }
  .main-banner-area-five .banner-text h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .main-banner-area-five .banner-text p {
    font-size: 15px;
  }
  .main-banner-area-five .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 30px;
  }
  .search-area {
    margin-top: 50px;
  }
  .search-area .top-search-from {
    padding: 20px;
    margin-bottom: 30px;
  }
  .search-area .top-search-from .searchs-wraps .form-control {
    font-size: 15px;
    padding-top: 0;
  }
  .search-area .top-search-from .searchs-wraps .form-control::-moz-placeholder {
    font-size: 15px;
  }
  .search-area .top-search-from .searchs-wraps .form-control::placeholder {
    font-size: 15px;
  }
  .search-area .top-search-from .searchs-wraps .search-btn {
    top: 9px;
    font-size: 30px;
  }
  .search-area .top-search-from .form-group {
    margin-bottom: 20px;
  }
  .main-banner-six {
    text-align: center;
  }
  .main-banner-six .container-fluid {
    max-width: 540px;
  }
  .main-banner-six .main-banner-content .content {
    margin-bottom: 30px;
    max-width: unset;
    margin-top: 50px;
  }
  .main-banner-six .main-banner-content .content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .main-banner-six .main-banner-content .content p {
    margin-bottom: 0;
  }
  .main-banner-six .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-six .banner-image-slider .banner-image {
    height: 500px;
  }
  .main-banner-six .shape {
    display: none;
  }
  .single-best-services .best-services-content {
    padding: 20px;
  }
  .abouts-six {
    padding-top: 0 !important;
  }
  .abouts-six .about-content {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .coming-soon-area .coming-soon-content {
    height: 100%;
  }
  .coming-soon-area .coming-soon-content h1 {
    font-size: 40px;
    line-height: 1.1;
    margin-top: 0;
  }
  .coming-soon-area .coming-soon-content p {
    font-size: 13px;
    margin-top: 15px;
  }
  .coming-soon-area .coming-soon-content #timer {
    margin-top: 20px;
  }
  .coming-soon-area .coming-soon-content #timer div {
    font-size: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .coming-soon-area .coming-soon-content #timer div span {
    font-size: 14px;
    margin-top: -5px;
  }
  .coming-soon-area .coming-soon-content #timer div::before {
    display: none;
  }
  .coming-soon-area .coming-soon-content .newsletter-form {
    margin-top: 35px;
  }
  .coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
    height: 55px;
    padding-left: 13px;
    font-size: 15px;
  }
  .coming-soon-area .coming-soon-content .newsletter-form button {
    position: relative;
    height: auto;
    padding: 14px 30px;
    font-size: 14px;
    margin-top: 15px;
  }
  .error-area {
    padding: 70px 0;
  }
  .error-area .error-content-wrap p {
    margin: 15px 0 20px;
  }
  .error-area .error-content-wrap h1 {
    font-size: 100px;
  }
  .symptoms-img {
    position: relative;
    bottom: -20px;
  }
  .main-contact-area #msgSubmit {
    position: unset;
    text-align: center;
    margin-top: 10px;
  }
  .about-bg-1 {
    height: 300px;
  }
  .about-img-2 {
    height: 300px;
  }
  .hero-slider-area-seven .slider-item::after {
    -webkit-clip-path: unset;
            clip-path: unset;
  }
  .second-facility-area.seven .p-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .second-facility-area.seven .shape {
    display: none;
  }
  .about-area-seven .about-content h2 {
    font-size: 25px;
  }
  .about-area-seven .about-content ul {
    margin-left: 0;
    display: block;
  }
  .about-area-seven .about-content .ml-0 {
    margin-bottom: 20px;
  }
  .about-area-seven .about-img {
    margin-top: 30px;
  }
  .counter-area .shape {
    display: none;
  }
  .services-area-seven .single-services .services-content {
    padding: 20px;
  }
  .doctors-area-two.seven .shape img {
    display: none;
  }
  .appointment-area-seven .shape {
    display: none;
  }
  .appointment-area-seven .appointment-here-form {
    padding: 20px;
  }
  .blog-area .shape {
    display: none;
  }
  .hero-slider-area-eight .slider-item {
    height: 100% !important;
  }
  .hero-slider-area-eight .slider-item .slider-text {
    padding: 20px;
    margin-top: 40px;
  }
  .second-facility-area.eight {
    padding-top: 50px;
    margin-top: 0;
  }
  .second-facility-area.eight .second-facility-item {
    text-align: center;
  }
  .second-facility-area.eight .p-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .about-img-1 {
    height: 300px;
    margin-top: 30px;
  }
  .about-area-seven .mb-30 {
    margin-bottom: 0 !important;
  }
  .single-pricing h1 {
    font-size: 35px;
  }
  .appointment-here-form .default-btn {
    margin: 0px auto 0;
  }
  .nav-area .navbar-area .main-nav {
    background-color: #25245e;
    padding: 10px 0;
  }
  .nav-area .navbar-area .main-nav .container {
    position: relative;
  }
  .nav-area .navbar-area .main-nav nav {
    padding: 0;
  }
  .nav-area .navbar-area .main-nav nav .navbar-collapse {
    border-top: 1px solid #eee;
    margin-top: 15px;
    padding-top: 8px;
    overflow-y: auto;
    max-height: 60vh;
  }
  .nav-area .navbar-area .main-nav nav .navbar-collapse::-webkit-scrollbar {
    width: 10px;
  }
  .nav-area .navbar-area .main-nav nav .navbar-collapse::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .nav-area .navbar-area .main-nav nav .navbar-collapse::-webkit-scrollbar-thumb {
    background: #888;
  }
  .nav-area .navbar-area .main-nav nav .navbar-collapse::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav {
    padding-right: 15px;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item {
    padding: 5px 0;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a i {
    display: none;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    width: 100%;
    margin-top: 10px;
    top: 0 !important;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li {
    padding: 0;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 15px;
    position: relative;
    opacity: 1 !important;
    visibility: visible !important;
    transform: scaleY(1);
    width: 90%;
    margin-top: 0;
    top: 0 !important;
  }
  .nav-area.four .navbar-area .main-nav {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #eee;
  }
  .nav-area.four .navbar-area .navbar-toggler .icon-bar {
    background: #25245e;
  }
  .nav-area.five .navbar-area .navbar-toggler .icon-bar {
    background: #25245e;
  }
  .nav-area .others-option {
    position: absolute;
    right: 60px;
    top: 6px;
  }
  .nav-area .others-option .subscribe .default-btn {
    padding: 10px 15px;
    font-size: 12px;
  }
  .header-area .top-header-area {
    text-align: center;
  }
  .modal-video-body {
    padding: 0 20px;
  }
  .hero-slider-area-seven .slider-item .slider-text {
    margin: 0px auto 0;
  }
  .blog-left-sidebar {
    padding-right: 0;
  }
  .blog-right-sidebar {
    padding-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area .top-header-area .header-content-right {
    float: right;
  }
  .main-banner-area .container-fluid {
    max-width: 540px;
    margin: auto;
  }
  .second-facility-item {
    padding: 15px;
  }
  .single-work .work-title {
    max-width: 325px;
  }
  .emergency-content ul li {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .subscribe-area .newsletter-form .default-btn {
    position: absolute;
    margin: unset;
    height: 50px;
  }
  .default-btn.active {
    margin-left: 30px;
  }
  .about-area.four .container-fluid {
    max-width: 540px;
  }
  .ours-doctors-area .container-fluid {
    max-width: 540px;
  }
  .counter-area.two .single-counter {
    padding: 50px 25px;
  }
  .main-contact-area .contact-form .default-btn {
    float: right;
    margin-top: 20px;
  }
  .main-contact-area .contact-form .contact-num {
    text-align: left;
  }
  .blog-details-area .blog-details-desc .article-content .entry-meta ul li {
    display: inline-block;
    font-size: 15px;
    margin-right: 10px;
  }
  .coming-soon-area .coming-soon-content h1 {
    font-size: 55px;
  }
  .coming-soon-area .coming-soon-content #timer div {
    font-size: 45px;
  }
  .coming-soon-area .coming-soon-content #timer div::before {
    font-size: 65px;
    right: -49px;
  }
  .main-contact-area #msgSubmit {
    position: absolute;
  }
  .about-area-seven .about-content ul {
    margin-left: 30px;
    display: inline-block;
  }
  .about-area-seven .about-content .ml-0 {
    margin-bottom: 0;
  }
  .single-doctors-two .doctor-img .doctors-link li a {
    margin: 0 2px;
  }
  .second-facility-area.eight .container {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-left-sidebar {
    padding-right: 0;
  }
  .blog-right-sidebar {
    padding-left: 0;
  }
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 20px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .pb-130 {
    padding-bottom: 40px;
  }
  h3 {
    font-size: 18px;
  }
  .default-btn {
    font-size: 16px;
    padding: 12px 25px;
  }
  .top-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .section-title {
    margin-bottom: 30px;
  }
  .section-title h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .header-area .top-header-area .header-content-left li {
    padding-right: 10px;
  }
  .header-area .top-header-area .header-content-left li:last-child {
    margin-bottom: 0;
  }
  .header-area .top-header-area .header-content-right li .option-item {
    display: none;
  }
  .main-banner-area {
    height: 100%;
    text-align: center;
    padding-top: 115px;
  }
  .main-banner-area .container-fluid {
    max-width: 720px;
    margin: auto;
  }
  .main-banner-area .container-fluid .col-lg-6.pr {
    padding-right: 15px !important;
  }
  .main-banner-area .banner-img-wrap .banner-img {
    max-width: 400px;
    position: unset;
    margin: 30px auto 0;
  }
  .main-banner-area .banner-img-wrap .banner-shape {
    right: 0;
    top: 0;
    left: 0;
    max-width: 100%;
    max-width: 400px;
    margin: auto;
  }
  .main-banner-area .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area .banner-text {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-top: 0;
  }
  .main-banner-area .banner-text span {
    font-size: 14px;
  }
  .main-banner-area .banner-text h1 {
    font-size: 38px;
    margin-bottom: 15px;
  }
  .main-banner-area .banner-text p {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: unset;
  }
  .main-banner-area .active {
    margin-top: 10px;
  }
  .first-facility-area {
    position: relative;
    max-width: 100%;
    padding: 20px;
    padding-top: 50px;
    z-index: 1;
  }
  .first-facility-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150px;
    width: 200px;
    height: 100%;
    background-color: #d6fbfd;
    z-index: -1;
  }
  .first-facility-area::after {
    content: "";
    position: absolute;
    top: 0;
    right: -150px;
    width: 200px;
    height: 100%;
    background-color: #d6fbfd;
    z-index: -1;
  }
  .first-facility-area .first-facility-item {
    padding-left: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .first-facility-area .first-facility-item i {
    position: unset;
    margin-bottom: 15px;
  }
  .first-facility-area .shape {
    display: none;
  }
  .second-facility-item {
    padding: 20px;
  }
  .second-facility-item img {
    max-width: 50px;
  }
  .about-area {
    padding-top: 0;
  }
  .about-area .about-img .shape-1 {
    display: none;
  }
  .about-area .about-img .shape-2 {
    display: none;
  }
  .about-area .about-img .shape-3 {
    display: none;
  }
  .about-area .about-content {
    margin-left: 0;
    margin-top: 30px;
  }
  .about-area .about-content h2 {
    font-size: 25px;
  }
  .about-area .about-content .default-btn {
    margin-top: 30px;
  }
  .about-img img {
    width: 100%;
  }
  .single-services {
    padding: 20px;
  }
  .single-services::before {
    display: none;
  }
  .single-doctor {
    padding: 20px;
  }
  .our-work-area .shape {
    display: none;
  }
  .single-work .work-title {
    max-width: 265px;
  }
  .single-counter h2 {
    font-size: 30px;
  }
  .single-counter p {
    font-size: 15px;
  }
  .appointment-here-form h2 {
    font-size: 25px;
    margin-bottom: 30px;
  }
  .appointment-here-form .shape {
    display: none;
  }
  .emergency-area {
    padding-bottom: 50px;
  }
  .emergency-area .shape {
    display: none;
  }
  .emergency-area .container-fluid {
    max-width: 720px;
  }
  .emergency-area .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .emergency-content {
    max-width: unset;
    padding-bottom: 20px;
  }
  .emergency-content h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .emergency-img {
    height: 500px;
  }
  .single-client {
    padding: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .single-blog .blog-content {
    padding: 20px;
  }
  .subscribe-area {
    text-align: center;
  }
  .subscribe-area h2 {
    font-size: 25px;
  }
  .subscribe-area p {
    font-weight: 500;
    margin-bottom: 30px;
  }
  .single-widget h3 {
    margin-bottom: 15px;
  }
  .client-area.c-bg {
    padding-bottom: 50px;
  }
  .main-banner-area-two {
    text-align: center;
    padding-top: 115px;
    height: 100%;
  }
  .main-banner-area-two .container-fluid {
    max-width: 720px;
  }
  .main-banner-area-two .container-fluid .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area-two .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 20px;
  }
  .main-banner-area-two .banner-text {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-top: 0;
  }
  .main-banner-area-two .banner-text span {
    font-size: 14px;
  }
  .main-banner-area-two .banner-text h1 {
    font-size: 35px;
    margin-bottom: 15px;
  }
  .main-banner-area-two .banner-text p {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: unset;
  }
  .main-banner-area-two .active {
    margin-top: 10px;
  }
  section.second-facility-area.two {
    padding-top: 50px;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .about-area.two {
    padding-top: 50px;
  }
  .symptoms-area .symptoms-content {
    margin-bottom: 30px;
  }
  .symptoms-area .symptoms-content h2 {
    font-size: 25px;
  }
  .symptoms-area .single-symptoms {
    margin-bottom: 20px;
  }
  .symptoms-area .single-symptoms h3 {
    padding-left: 25px;
    font-size: 15px;
  }
  .single-prevention {
    padding-left: 0;
    text-align: center;
  }
  .single-prevention i {
    position: relative;
    margin-bottom: 25px;
    left: 0;
  }
  .make-appointment-area .make-appointment-content h2 {
    margin-bottom: 18px;
    font-size: 24px;
  }
  .make-appointment-area .contact-form {
    padding: 20px;
  }
  .contact-img {
    margin-top: 30px;
  }
  .prevention-area {
    padding-bottom: 50px;
  }
  .prevention-area .container-fluid {
    max-width: 720px;
  }
  .faq-img {
    margin-bottom: 30px;
  }
  .hero-slider-area {
    text-align: center;
  }
  .hero-slider-area .slider-item {
    height: 100%;
    padding-top: 115px;
    padding-bottom: 150px;
  }
  .hero-slider-area .slider-item .slider-text h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .hero-slider-area .slider-item .slider-text h1 br {
    display: none;
  }
  .hero-slider-area .slider-item .slider-text p {
    margin-bottom: 30px;
    font-size: 14px;
  }
  .hero-slider-area .slider-item .video-btn {
    margin: 20px auto 0;
  }
  .second-facility-area.three {
    padding-top: 50px;
    margin: 0;
  }
  .second-facility-area.three .container {
    max-width: 1110px;
  }
  .second-facility-area.three .second-facility-item {
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
  }
  .about-area-three .about-content h2 {
    font-size: 25px;
  }
  .about-area-three .about-content .default-btn {
    margin-top: 5px;
  }
  .about-area-three .about-img {
    margin-top: 30px;
  }
  .about-area-three .about-img::before {
    display: none;
  }
  .about-area-three .shape-3 {
    display: none;
  }
  .how-its-work-content .content-wrap h3 {
    font-size: 16px;
  }
  .how-work-img {
    margin-top: 30px;
  }
  .assessment-img {
    margin-bottom: 30px;
  }
  .assessment-center-content h2 {
    font-size: 25px;
  }
  .assessment-center-content ul li {
    font-size: 14px;
  }
  .assessment-center-content ul li h3 {
    font-size: 15px;
  }
  .emergency-contents h2 {
    font-size: 25px;
  }
  .assessments-img {
    margin-top: 30px;
  }
  .assessments-img img {
    width: 100%;
  }
  .operate-area .operate-img {
    position: unset;
    margin-bottom: 30px;
  }
  .operate-area .operate-text {
    margin-bottom: 30px;
  }
  .operate-area .operate-text h2 {
    font-size: 25px;
  }
  .main-banner-area-four {
    text-align: center;
    height: 100%;
    padding-top: 115px;
  }
  .main-banner-area-four .container-fluid {
    max-width: 720px;
  }
  .main-banner-area-four .banner-text {
    max-width: unset;
    top: 0;
  }
  .main-banner-area-four .banner-text span {
    margin-bottom: 10px;
  }
  .main-banner-area-four .banner-text h1 {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .main-banner-area-four .banner-text p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .main-banner-area-four .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 20px;
  }
  .about-area.four {
    padding-top: 50px;
  }
  .about-area.four .about-content {
    max-width: unset;
  }
  .about-area.four .container-fluid {
    max-width: 720px;
  }
  .about-area.four .col-lg-6.pl-0 {
    padding-left: 15px !important;
  }
  .about-area.four .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .about-area.four.mission {
    padding-top: 0;
  }
  .about-area.four.mission .about-content {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .ours-doctors-area {
    padding-bottom: 50px;
  }
  .ours-doctors-area .container-fluid {
    max-width: 720px;
  }
  .ours-doctors-area .doctors-content {
    padding-bottom: 30px;
    margin-right: 0;
    max-width: unset;
  }
  .ours-doctors-area .doctors-content h2 {
    font-size: 25px;
  }
  .ours-doctors-area .col-lg-6.pr-0 {
    margin-right: 15px !important;
  }
  .ours-doctors-area .doctors-img {
    height: 300px;
  }
  .main-banner-area-five {
    text-align: center;
    height: 100%;
    padding-top: 115px;
  }
  .main-banner-area-five .container-fluid {
    max-width: 720px;
  }
  .main-banner-area-five .col-lg-5.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-area-five .banner-text {
    max-width: unset;
    top: 0;
  }
  .main-banner-area-five .banner-text span {
    margin-bottom: 10px;
  }
  .main-banner-area-five .banner-text h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .main-banner-area-five .banner-text p {
    font-size: 15px;
  }
  .main-banner-area-five .banner-img-wrap .banner-img {
    position: unset;
    margin-top: 30px;
  }
  .top-search-from {
    margin-bottom: 30px;
  }
  .main-banner-six {
    text-align: center;
  }
  .main-banner-six .container-fluid {
    max-width: 720px;
  }
  .main-banner-six .main-banner-content .content {
    margin-bottom: 30px;
    max-width: unset;
    margin-top: 80px;
  }
  .main-banner-six .main-banner-content .content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .main-banner-six .main-banner-content .content p {
    margin-bottom: 0;
  }
  .main-banner-six .col-lg-6.pr-0 {
    padding-right: 15px !important;
  }
  .main-banner-six .banner-image-slider .banner-image {
    height: 500px;
  }
  .main-banner-six .shape {
    display: none;
  }
  .single-best-services .best-services-content {
    padding: 20px;
  }
  .abouts-six {
    padding-top: 0 !important;
  }
  .abouts-six .about-content {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .about-area.about-page {
    padding-top: 50px;
  }
  .about-img.two {
    margin-top: 30px;
  }
  .page-title-area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .page-title-area .page-title-content h2 {
    font-size: 30px;
  }
  .client-area .single-client.mr-0.ml-0 {
    margin-bottom: 30px;
  }
  .page-navigation-area {
    margin: 0px auto 0;
  }
  .faq-contact-area .contact-wrap {
    padding: 30px;
  }
  .services-page-one {
    margin-top: 50px !important;
  }
  .services-page-one::before {
    display: none;
  }
  .services-page-one::after {
    display: none;
  }
  .first-facility-area.mt-0.services-page-one {
    margin-top: 0 !important;
    margin-bottom: 50px;
  }
  .services-details-area img {
    width: 100%;
  }
  .services-details-area .services-img {
    margin-bottom: 30px;
  }
  .services-details-area .scrives-item-3 .share {
    float: left;
    margin-top: 20px;
  }
  .services-details-text {
    padding-left: 15px;
    padding-right: 15px;
  }
  .services-details-text h2 {
    font-size: 25px;
  }
  .doctors-sidebar img {
    width: 100%;
  }
  .doctors-sidebar .availability {
    padding: 20px;
  }
  .doctors-detailss .doctors-history {
    margin-top: 30px;
    padding: 20px;
  }
  .doctors-detailss .doctors-history h2 {
    font-size: 25px;
  }
  .doctors-detailss .doctors-history span {
    font-size: 15px;
  }
  .b-d-s-img img {
    width: 100%;
  }
  aside#secondary {
    margin-top: 35px;
  }
  .blog-left-sidebar {
    margin-bottom: 30px;
  }
  .blog-right-sidebar {
    margin-top: 30px;
  }
  .single-contact-info {
    padding: 50px;
  }
  .single-contact-map iframe {
    height: 300px;
  }
  .symptoms-img {
    position: relative;
    bottom: -20px;
  }
  .about-area.about-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-bg-1 {
    height: 400px;
  }
  .about-img-2 {
    height: 300px;
  }
  .hero-slider-area-seven .slider-item::after {
    -webkit-clip-path: unset;
            clip-path: unset;
  }
  .second-facility-area.seven .p-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .second-facility-area.seven .shape {
    display: none;
  }
  .about-area-seven .about-content h2 {
    font-size: 25px;
  }
  .about-area-seven .about-content ul {
    margin-left: 0;
    display: block;
  }
  .about-area-seven .about-content .ml-0 {
    margin-bottom: 20px;
  }
  .about-area-seven .about-img {
    margin-top: 30px;
  }
  .counter-area .shape {
    display: none;
  }
  .services-area-seven .single-services .services-content {
    padding: 20px;
  }
  .doctors-area-two.seven .shape img {
    display: none;
  }
  .appointment-area-seven .shape {
    display: none;
  }
  .appointment-area-seven .appointment-here-form {
    padding: 20px;
  }
  .blog-area .shape {
    display: none;
  }
  .about-area-seven .about-content ul {
    margin-left: 30px;
    display: inline-block;
  }
  .about-area-seven .about-content .ml-0 {
    margin-bottom: 0;
  }
  .hero-slider-area-eight .slider-item {
    height: 100% !important;
  }
  .hero-slider-area-eight .slider-item .slider-text {
    padding: 20px;
    margin: 40px auto 0;
  }
  .second-facility-area.eight {
    padding-top: 20px;
    margin-top: 0;
  }
  .second-facility-area.eight .container {
    max-width: 720px;
  }
  .second-facility-area.eight .second-facility-item {
    text-align: center;
  }
  .second-facility-area.eight .p-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .about-img-1 {
    height: 300px;
    margin-top: 30px;
  }
  .about-area-seven .mb-30 {
    margin-bottom: 0 !important;
  }
  .single-pricing h1 {
    font-size: 35px;
  }
  .appointment-here-form .default-btn {
    margin: 0px auto 0;
  }
  .second-facility-area.seven .container {
    max-width: 720px;
  }
  .nav-area .navbar-area .main-nav {
    background-color: #25245e;
  }
  .nav-area .navbar-area .main-nav nav {
    padding: 10px 0;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item {
    padding: 6px 0;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a {
    font-size: 14px;
    margin: 0 7px;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a i {
    font-size: 12px;
    top: 2px;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
    width: 220px;
  }
  .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li {
    padding: 0;
  }
  .nav-area .navbar-area .others-option .subscribe .default-btn {
    padding: 10px 9px;
    font-size: 12px;
  }
  .nav-area.four .navbar-area .main-nav {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #eee;
  }
  .nav-area.four .navbar-area .navbar-toggler .icon-bar {
    background: #25245e;
  }
  .nav-area.five .navbar-area .navbar-toggler .icon-bar {
    background: #25245e;
  }
  .hero-slider-area-seven .slider-item .slider-text {
    margin: 0 auto 0;
  }
  .first-facility-area.services-page-one {
    width: 100%;
  }
  .search-area .form-group {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-left-sidebar {
    padding-right: 0;
  }
  .blog-right-sidebar {
    padding-left: 0;
  }
  .pb-130 {
    padding-bottom: 100px;
  }
  .main-banner-area {
    height: 1000px;
  }
  .main-banner-area .container-fluid {
    max-width: 960px;
  }
  .main-banner-area .banner-text {
    top: -20px;
  }
  .main-banner-area .banner-text h1 {
    font-size: 50px;
  }
  .main-banner-area .banner-text p {
    font-weight: 500;
  }
  .main-banner-area .banner-img-wrap .banner-img {
    top: -190px;
  }
  .about-area .about-content h2 {
    font-size: 35px;
  }
  .top-title {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 35px;
  }
  .appointment-here-form h2 {
    font-size: 35px;
  }
  .emergency-content h2 {
    font-size: 35px;
  }
  .emergency-area .shape {
    display: none;
  }
  .emergency-area .container-fluid {
    max-width: 990px;
    margin-left: auto;
    margin-right: unset;
  }
  .single-blog .blog-content {
    padding: 20px;
  }
  .subscribe-area h2 {
    font-size: 35px;
  }
  .main-banner-area-two .container-fluid {
    max-width: 960px;
  }
  .main-banner-area-two .banner-text {
    top: 10px;
  }
  .main-banner-area-two .banner-text h1 {
    font-size: 50px;
  }
  .main-banner-area-two .banner-text p {
    font-weight: 500;
  }
  .main-banner-area-two .banner-img-wrap .banner-img {
    top: 25px;
  }
  .about-area.two .about-img .shape-1 {
    left: -75px;
    top: -70px;
  }
  .about-area.two .about-img .shape-1 img {
    max-width: 560px;
  }
  .about-area.two .about-content {
    margin-top: -6px;
    margin-bottom: -6px;
  }
  .symptoms-area {
    padding-bottom: 85px;
  }
  .single-symptoms h3 {
    font-size: 16px;
  }
  .counter-area.two .single-counter {
    padding: 50px 25px;
  }
  .counter-area.two .single-counter h2 {
    font-size: 30px;
  }
  .prevention-area .container-fluid {
    max-width: 990px;
  }
  .user-area-all-style .contact-form-action {
    padding: 20px;
  }
  .user-area-all-style .contact-form-action form .default-btn {
    padding: 15px 35px;
  }
  .user-area-all-style .log-in-img {
    height: 400px;
  }
  .doctors-detailss .doctors-history h2 {
    font-size: 30px;
  }
  .single-contact-info {
    padding: 50px 30px;
  }
  .single-contact-map iframe {
    height: 323px;
  }
  .first-facility-area {
    width: 960px;
    margin: auto;
    right: 0;
  }
  .first-facility-item {
    padding-left: 80px;
  }
  .first-facility-item i {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 35px;
  }
  .about-area-three .about-img::before {
    display: none;
  }
  .about-area-three .shape-3 {
    display: none;
  }
  .operate-area .operate-img {
    bottom: -148px;
  }
  .main-banner-area-four .banner-text {
    max-width: 540px;
    top: 55px;
  }
  .main-banner-area-four .banner-text h1 {
    font-size: 55px;
  }
  .main-banner-area-four .banner-img-wrap .banner-img {
    top: 0;
  }
  .about-area.four .container-fluid {
    max-width: 945px;
  }
  .about-area.four .about-content {
    margin-left: 0;
  }
  .ours-doctors-area .doctors-content {
    max-width: 455px;
    margin-right: 0;
  }
  .main-banner-six .shape-2 {
    top: 84%;
    left: 17%;
  }
  .main-banner-six .main-banner-content .content {
    max-width: 450px;
  }
  .single-professionals .location .right {
    margin-left: 10px;
  }
  .symptoms-img {
    position: relative;
    bottom: -10px;
  }
  .second-facility-area.seven .container {
    max-width: 930px;
  }
  .single-blog .blog-content h3 {
    font-size: 18px;
  }
  .second-facility-area.eight {
    margin-top: 0;
  }
  .second-facility-area.eight .container {
    max-width: 930px;
  }
  .second-facility-area.eight .second-facility-item {
    padding: 20px;
  }
  .about-area-seven .about-content h2 {
    font-size: 35px;
  }
  .hero-slider-area-eight .slider-item {
    height: 100% !important;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .hero-slider-area-seven .slider-item {
    height: 100% !important;
    padding-top: 120px;
    padding-bottom: 100px;
  }
  .main-banner-area-five .banner-text {
    max-width: 535px;
  }
  .main-banner-area-five .banner-text h1 {
    font-size: 55px;
  }
  .hero-slider-area-seven .slider-item .slider-text {
    margin: 0 auto 0;
  }
  .hero-slider-area-eight .slider-item .slider-text {
    margin-top: 0;
  }
  .hero-slider-area-eight .slider-item .slider-text h1 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 1700px) {
  .main-banner-area {
    height: 100vh;
  }
  .main-banner-area .banner-text {
    margin-top: -70px;
  }
  .main-banner-area .banner-img-wrap .banner-img {
    max-width: unset;
    top: -125px;
  }
  .main-banner-area .banner-img-wrap .banner-shape {
    max-width: unset;
    right: -155px;
  }
  .first-facility-area {
    bottom: 0;
  }
  .main-banner-area-two {
    height: 100vh;
  }
  .main-banner-area-two .banner-img-wrap .banner-img {
    top: -200px;
  }
  .main-banner-area-four {
    height: 100vh;
  }
  .main-banner-area-five .banner-img-wrap .banner-img {
    top: -100px;
  }
  .main-banner-six .shape {
    width: unset;
  }
  .main-banner-six .shape-2 {
    width: unset;
  }
  .hero-slider-area .slider-item {
    height: 100vh;
  }
  .emergency-area .shape {
    display: block;
    max-width: 240px;
    width: 100%;
  }
  .main-banner-area-five .banner-text {
    max-width: 690px;
  }
  .main-banner-area-four .banner-text {
    max-width: 790px !important;
  }
  .main-banner-area-five .banner-text {
    max-width: 795px !important;
  }
  .prevention-area .prevention-wrap {
    max-width: 965px !important;
  }
}
@media only screen and (max-width: 991px) {
  .mean-container .mean-bar {
    background-color: #25245e;
    box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.05);
    height: 70px;
  }
  .mean-container a.meanmenu-reveal {
    padding: 24px 15px 0 0;
    color: #ffffff;
  }
  .mean-container a.meanmenu-reveal span {
    background-color: #ffffff;
    position: relative;
    top: 8px;
    margin-top: -5px;
  }
  .mobile-nav .logo {
    top: 14px;
  }
  .mobile-nav.mean-container .mean-nav {
    margin-top: 70px;
  }
  .mobile-nav.mean-container .mean-nav ul li a:hover {
    color: #05e8c2;
  }
  .mobile-nav.mean-container .mean-nav ul li a.active {
    color: #05e8c2;
  }
  .fixed-top {
    position: unset;
  }
  .mobile-nav nav .navbar-nav {
    height: 325px;
  }
}
@media only screen and (min-width: 1400px) {
  .main-banner-area .banner-text {
    max-width: 630px;
  }
  .main-banner-area-two .banner-text {
    max-width: 635px;
  }
  .prevention-area .prevention-wrap {
    max-width: 900px;
    margin-left: auto;
  }
  .second-facility-area.three .container {
    max-width: 1290px;
    width: 100%;
  }
  .main-banner-area-four .banner-text {
    max-width: 765px;
  }
  .about-area.four .about-content {
    max-width: 635px;
  }
  .ours-doctors-area .doctors-content {
    max-width: 600px;
  }
  .main-banner-area-five .banner-text {
    max-width: 765px;
    width: 100%;
  }
  .emergency-content {
    max-width: 640px;
  }
  .main-banner-six .main-banner-content .content {
    max-width: 635px;
  }
  .second-facility-area.seven .container {
    max-width: 1295px;
    width: 100%;
  }
  .second-facility-area.eight .container {
    max-width: 1295px;
  }
  .first-facility-area.services-page-one {
    max-width: 1290px;
    width: 100%;
  }
}/*# sourceMappingURL=responsive.css.map */